import React from 'react';  
import { Dropdown } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css';
import axios from "axios"

const url = "/";  

export default class Layers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Background: [],
            Bucket: [],
            Cone: [],
            Eyes: [],
            Flying: [],
            HeadGear: [],
            Holding: [],
            Mouth: [],
            Pants: [],
            BackgroundIndex: 0,
            BucketIndex: 0,
            ConeIndex: 0,
            EyesIndex: 0,
            FlyingIndex: 0,
            HeadGearIndex: 0,
            HoldingIndex: 0,
            MouthIndex: 0,
            PantsIndex: 0 ,
            _0005_Shadow: '/layout/img/portfolio/_0005_Shadow.png',
            _0004_Belt: '/layout/img/portfolio/_0004_Belt.png', 
            _0003_Body: '/layout/img/portfolio/_0003_Body.png', 
            _0002_BodyHighlights: '/layout/img/portfolio/_0002_BodyHighlights.png', 
            _0001_BodyShadows: '/layout/img/portfolio/_0001_BodyShadows.png',
            _0000_BodyLines: '/layout/img/portfolio/_0000_BodyLines.png',
            meta: null 
        };
        this.getlayers = this.getlayers.bind(this);
        this.loadLayers = this.loadLayers.bind(this);
        this.renderBuilder = this.renderBuilder.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        this.loadLayers().then(
            (resolve) => {
                this.state.Background.map((element, index) => { if (element.name == 'Sun') { this.setState({ BackgroundIndex: index }) } });
                this.state.Bucket.map((element, index) => { if (element.name == 'None') { this.setState({ BucketIndex: index }) } });
                this.state.Cone.map((element, index) => { if (element.name == 'None') { this.setState({ ConeIndex: index }) } });
                this.state.Eyes.map((element, index) => { if (element.name == 'Crossed') { this.setState({ EyesIndex: index }) } });
                this.state.Flying.map((element, index) => { if (element.name == 'Cone And Bucket') { this.setState({ FlyingIndex: index }) } }); 
                this.state.HeadGear.map((element, index) => { if (element.name == 'None') { this.setState({ HeadGearIndex: index }) } });
                this.state.Holding.map((element, index) => { if (element.name == 'None') { this.setState({ HoldingIndex: index }) } });
                this.state.Mouth.map((element, index) => { if (element.name == 'Smile') { this.setState({ MouthIndex: index }) } });
                this.state.Pants.map((element, index) => { if (element.name == 'Orange') { this.setState({ PantsIndex: index }) } }); 
            });
    };
     
    loadLayers() {
        let me = this;
        return new Promise((resolve, reject) => {
            var Background = [];
            var Bucket = [];
            var Cone = [];
            var Eyes = [];
            var Flying = [];
            var HeadGear = [];
            var Holding = [];
            var Mouth = [];
            var Pants = [];
            this.getlayers("Background").then((result) => {
                Background = result;
                this.getlayers("Bucket").then((result) => {
                    Bucket = result;
                    this.getlayers("Cone").then((result) => {
                        Cone = result;
                        this.getlayers("Eyes").then((result) => {
                            Eyes = result;
                            this.getlayers("Flying").then((result) => {
                                Flying = result;
                                this.getlayers("Head Gear").then((result) => {
                                    HeadGear = result;
                                    this.getlayers("Holding").then((result) => {
                                        Holding = result;
                                        this.getlayers("Mouth").then((result) => {
                                            Mouth = result;
                                            this.getlayers("Pants").then((result) => {
                                                Pants = result;
                                                this.setState(
                                                    {
                                                        Background: Background,
                                                        Bucket: Bucket,
                                                        Cone: Cone,
                                                        Eyes: Eyes,
                                                        Flying: Flying,
                                                        HeadGear: HeadGear,
                                                        Holding: Holding,
                                                        Mouth: Mouth,
                                                        Pants: Pants
                                                    });
                                                resolve(true);
                                            }, (error) => { })
                                        }, (error) => { })
                                    }, (error) => { })
                                }, (error) => { })
                            }, (error) => { })
                        }, (error) => { })
                    }, (error) => { })
                }, (error) => { })
            }, (error) => { })
        });
    }

    getlayers(which) {
        let me = this;
        return new Promise((resolve, reject) => {
            fetch(url + "Layers/layers?which=" + which,
                {
                    method: "GET"
                })
                .then((res) => {
                    return res.json();
                })
                .then((json) => {
                    if (json.success) {
                        resolve(json.data);
                    } else {
                        resolve([]);
                    }
                }).catch(error => { resolve([]) });
        });
    } 

    getOptions(values) {
        return values.map((p, i) => ({
            key: i,
            text: p.name,
            value: p.index,
        }));
    }


    create() {
        let me = this;  
        let data = JSON.stringify([ 
                me.state.Background[me.state.BackgroundIndex],
            {
                index: 2, name: me.state._0005_Shadow, path: "_0005_Shadow.png", att_name: "", att_value: "" },
                me.state.Cone[me.state.ConeIndex],
                me.state.Pants[me.state.PantsIndex],
                { index: 2, name: me.state._0004_Belt, path:"_0004_Belt.png", att_name: "", att_value: "" },
                { index: 2, name: me.state._0003_Body, path: "_0003_Body.png", att_name: "", att_value: "" },
                { index: 2, name: me.state._0002_BodyHighlights, path: "_0002_BodyHighlights.png", att_name: "", att_value: "" }, 
                { index: 2, name: me.state._0001_BodyShadows, path: "_0001_BodyShadows.png", att_name: "", att_value: "" },
                { index: 2, name: me.state._0000_BodyLines, path: "_0000_BodyLines.png", att_name: "", att_value: "" },
                me.state.Eyes[me.state.EyesIndex] ,
                me.state.HeadGear[me.state.HeadGearIndex] , 
                me.state.Mouth[me.state.MouthIndex] ,
                me.state.Holding[me.state.HoldingIndex] ,
                me.state.Bucket[me.state.BucketIndex] ,
                me.state.Flying[me.state.FlyingIndex]
            ]
        );    

        me.props.load_handler("Check your Li'l Bit's uniqueness....");
        var formData = new FormData();
        formData.append('layers', data);

        axios.post(url + "Layers/check", formData)
            .then(res => {
                me.setState({ LoadingText: '' });
                if (res.data.success) {
                    let m = res.data.data;
                    m.wallet_id = me.props.account;
                    me.setState({ meta: m });
                } else {
                    me.setState({ meta: null });
                }
                me.props.alert_handler(res.data.message);
                me.props.load_handler("");
            }).catch(error => {
                me.props.alert_handler(error.message); 
                me.props.load_handler("");
            });
    }


    send() {
        let me = this;
        let data = JSON.stringify(me.state.meta);

        me.props.load_handler("Submitting your Li'l Bit build....");
        var formData = new FormData();
        formData.append('meta', data);

        axios.post(url + "Layers/send", formData)
            .then(res => {
                me.setState({ LoadingText: '' });
                if (res.data.success) { 
                    me.setState({ meta: null });
                } 
                me.props.alert_handler(res.data.message);
                me.props.load_handler("");

            }).catch(error => {
                me.props.alert_handler(error.message);
                me.props.load_handler("");
            });
    }

    global_download(b64, name) {
    //For IE using atob convert base64 encoded data to byte array
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var byteCharacters = atob(b64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
            type: 'image/png'
        });
        window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
        // Directly use base 64 encoded data for rest browsers (not IE) 
        var link = document.createElement('a');
        link.innerHTML = name;
        link.download = name;
        link.href = 'data:image/png;base64,' + b64;
        link.click();
    }
}

    renderBuilder() {
        let me = this; 
       
        if (me.state.Background.length > 0) {
 
            return (<React.Fragment> 
                <div style={{ paddingBottom: '12px'}} > 
                   1. Select the attribues from the dropdowns below<br/>
                   2. Click the <b>Check 1 Of A Kind</b> button to see if your build is a unique Li'l Bit<br/>
                    3. If unique, Click the <b>Send</b> button to submit your build<br />
                    <br />
                    NOTE: On successful submission, your custom Li'l Bit will be minted that night and sent to your wallet, so check your Hidden on OpenSea the next day.
                </div><div className="row content" data-aos="fade-up">
                    <div className="col-lg-6 col-md-6" >



                        <table className="build_table"><tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Bucket:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Bucket)}
                                    placeholder={"Select Bucket"}
                                    value={me.state.BucketIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ BucketIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Cone:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Cone)}
                                    placeholder={"Select Cone"}
                                    value={me.state.ConeIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ ConeIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Eyes:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Eyes)}
                                    placeholder={"Select Eyes"}
                                    value={me.state.EyesIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ EyesIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Flying:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Flying)}
                                    placeholder={"Select Flying"}
                                    value={me.state.FlyingIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ FlyingIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>HeadGear:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.HeadGear)}
                                    placeholder={"Select HeadGear"}
                                    value={me.state.HeadGearIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ HeadGearIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Holding:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Holding)}
                                    placeholder={"Select Holding"}
                                    value={me.state.HoldingIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ HoldingIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Mouth:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Mouth)}
                                    placeholder={"Select Mouth"}
                                    value={me.state.MouthIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ MouthIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Pants:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Pants)}
                                    placeholder={"Select Pants"}
                                    value={me.state.PantsIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ PantsIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><label>Background:</label></td>
                                <td><Dropdown id={"back"}
                                    fluid
                                    selection
                                    search={true}
                                    options={me.getOptions(me.state.Background)}
                                    placeholder={"Select Background"}
                                    value={me.state.BackgroundIndex}
                                    onChange={(e, { value }) => {
                                        me.setState({ BackgroundIndex: value, meta: null })
                                    }}
                                    className="dropdown"
                                /></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }} colSpan="2">
                                    {me.state.meta != null ?
                                        <button onClick={() => { me.send() }} className="cta-button">
                                            Send
                                        </button> : <button onClick={() => { me.create() }} className="cta-button">
                                            Check 1 Of A Kind
                                        </button>
                                    }
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div className="col-lg-6 col-md-1" >


                        <div className="lcontainer">
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Background[me.state.BackgroundIndex].path} className="layers" />
                            <img alt="" src={me.state._0005_Shadow} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Cone[me.state.ConeIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Pants[me.state.PantsIndex].path} className="layers" />
                            <img alt="" src={me.state._0004_Belt} className="layers" />
                            <img alt="" src={me.state._0003_Body} className="layers" />
                            <img alt="" src={me.state._0002_BodyHighlights} className="layers" />
                            <img alt="" src={me.state._0001_BodyShadows} className="layers" />
                            <img alt="" src={me.state._0000_BodyLines} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Eyes[me.state.EyesIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.HeadGear[me.state.HeadGearIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Mouth[me.state.MouthIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Holding[me.state.HoldingIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Bucket[me.state.BucketIndex].path} className="layers" />
                            <img alt="" src={url + 'Layers/image?path=' + me.state.Flying[me.state.FlyingIndex].path} className="layers" />
                        </div> 


                    <table className="build_table"><tbody>
                        <tr>  
                            <td valign="top" style={{ padding: '12px' }} > 

                             </td>
                        </tr> 
                        </tbody>
                    </table>
                </div>

                 </div>
                 </React.Fragment >
                 );
        } else {
            return (<React.Fragment>
                <div className="toolbar">
                    Loading...
                </div></React.Fragment>);
        }
    }
     
    render() { 
        return (
            <React.Fragment> 
                {this.renderBuilder()} 
            </React.Fragment>
        );
    }
} 