import React from 'react';  
 

export default class Joe extends React.Component { 
    constructor(props) {
        super(props); 
    }  

    render() {  
        return (
            <React.Fragment> 
                <main className="main">  
                    <section className="toon u"><a name="about" /> 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                    <h1>About Me</h1>    
                                    <p>An art major in college that became a computer programmer by trade.  <img src="/layout/img/portfolio/unicorn.png" height="200px" align="left" /> 
                                        <br/><br/>A competitive powerlifter at nights and some weekends. 
                                        <br/><br/>A motorcycle rider when the weather's nice and sometimes when it's not so nice. 
                                        <br /><br />A hobbyist photographer 
                                        <br/><br/> A digital artist 
                                        <br/><br/> As a hobbyist photographer, I love capturing the beauty of the world through the lens of a camera.
                                        <br /><br />It's a way of expressing my creativity and connecting with the world around me.
                                        <img src="/layout/img/portfolio/cone.png" height="200px" align="right" />
                                        <br /><br /><img src="/layout/img/portfolio/coneB.png"  height="200px" align="left" />As a digital artist, the mix of my artistic background, with my occupation as a programmer, made for the perfect step into the new world of NFT creation. I
                                        write my own blockchain smart contracts, program my web3 apps, and create all the digital art for my numerous NFT collections. 
                                        <br /><br />Both photography and digital art provide you with a means to express myself and share my unique perspective with the world.
                                    </p> 
                                </div>
                            </div>
                        </div>
                    </section>  
                     
                    <section className="toon u"><a name="links" /> 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                    <h1>Links</h1> 
                                    <div className="row content  story" data-aos="fade-up">
                                        <div className="col-lg-12 pt-4 pt-lg-0">
                                            <a target="new" href="http://joevianiphoto.com/">Photography</a><br />
                                            <a target="new" href="https://www.facebook.com/joevianiphoto">Facebook</a><br />
                                            <a target="new" href="https://www.instagram.com/joevianiphoto/">Instagram</a><br />
                                            <a target="new" href="https://twitter.com/VianiJoe/">Twitter</a><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 
                </main>   
             
            </React.Fragment>
        );
    }
} 

 
