import React from 'react';  
import { ethers } from "ethers";
import AlertBox from './AlertBox';  
import Loader from './Loader';   
import Mint from './Mint.js';


export default class Lilbit extends React.Component { 
    constructor(props) {
        super(props);
  
        this.state = {
            AlertMessage: '',
            account: '',
            LoadMessage: '',
            network: '' 
        } 
         
        this.NETWORK_POLY = process.env.REACT_APP_NETWORK_POLY;   
        this.connect = this.connect.bind(this);
        this.checkAccount = this.checkAccount.bind(this);
        this.switch = this.switch.bind(this);
        this.loadcontract = this.loadcontract.bind(this);
        this.alert_handler = this.alert_handler.bind(this);
        this.load_handler = this.load_handler.bind(this); 
  
        this.pony = React.createRef(); 
    } 
     
    componentDidMount() {
        this.checkAccount(); 
    }

    alert_handler(message){
        let me = this;
        me.setState({ AlertMessage: message });
    }

    load_handler(message) {
        let me = this;
        me.setState({ LoadMessage: message });
    }
     
    async connect() {
        let me = this;
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                me.checkAccount()
            } catch (err) {
                me.setState({ AlertMessage: err.message })
            }
        } else {
            me.setState({ AlertMessage: 'No wallet installed. Please install a browser wallet. Metamask is recommended' })
        }
    } 

    async loadcontract() {
        let me = this; 
        await me.pony.current.setcontract(me.state.account);  
    }
  
    async checkAccount() {
        let me = this;
        if (window.ethereum) {

            window.ethereum.on('accountsChanged', function (accounts) {  
                window.location.reload(); 
            });
 
            window.ethereum.on('networkChanged', function (networkId) { 
                window.location.reload(); 
            }); 

            let e = "";
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
                .catch((err) => { e = err.message; } );

            if (e != "") {
                me.setState({ AlertMessage: e })
            } else {
                me.setState({ account: accounts[0], network: window.ethereum.chainId });
            } 

            await me.loadcontract();  
 
        } else {
            me.setState({ AlertMessage: 'No wallet installed. Please install a browser wallet. Metamask is recommended' })
        }
    }

    async switch() {
        let me = this;
        const { ethereum } = window;

        try {
            const provider = new ethers.providers.Web3Provider(ethereum);
    
            provider.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: me.NETWORK_POLY }],
            }).then(
                (result) => {
                    return me.NETWORK_POLY;
                })
                .catch(
                    (error) => {
                        if (error.code === 4902) {
                            provider.provider.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: me.NETWORK_POLY,
                                        chainName: 'Polygon',
                                        rpcUrls: ['https://polygon-rpc.com '],
                                        blockExplorerUrls: ['https://polygonscan.com/'],
                                        nativeCurrency: {
                                            symbol: 'MATIC',
                                            decimals: 18
                                        }
                                    }
                                ]
                            }).then(() => {
                                return me.NETWORK_POLY;
                            });
                        }
                    });
             
        }
        catch (err) {
            return '';
            me.setState({ AlertMessage: err.message });
        }
    }
 
    render() { 

        let me = this;  
         
        let netbutton = <a href='#' onClick={() => { me.connect() }} style={{ float: "right", marginLeft: '100px' }} className="menu">Connect to Wallet</a>;   

        let c = me.pony.current != null ? me.pony.current.getcount() : 0;;
        let b = c > 0 ? <li><a className="nav-link scrollto" href="#builder">Build a Bit</a></li> : null; 

        if (me.state.account != '') {
            netbutton =  me.state.network != this.NETWORK_POLY ?
                <a href='#' onClick={() => { me.switch() }} className="nav-link ">Switch to Polygon to Mint</a> :
                <a href='#' style={{ float: "right", marginLeft: '100px' }} className="nav-link "> Wallet: {me.state.account.substring(0, 4)}...{me.state.account.substring(me.state.account.length - 4)}</a>; 
        }   
        return (
            <React.Fragment> 
                <main className="main"> 
                 
                    <section className="toon u"><a name="about" /> 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                    <img src="/layout/img/portfolio/banner.png" style={{ width: '100%' }} />
                                    <h1>The Li'l Bit Lore</h1>
                                    <p> <img src={"/layout/img/portfolio/cone.png"} align="right" />
                                        <br /><br/>In numerous subs on Reddit, a curious cartoon character named Li'l Bit emerged from the digital realm. Li'l Bit was a magical creation born from a fusion of augmented reality and a touch of crypto magic. He was created to promote the wonders of Bitcone ($CONE) and blockchain technology to the members of Reddit. 
                                        <br /><br />At first, people were skeptical of Li'l Bit's mission. They couldn't fathom how this cute little character could educate them about the complex world of cryptocurrencies. But Li'l Bit was persistent. He popped up on various Reddit subs and Twitter, charming his way into people's hearts with his infectious enthusiasm. 
                                        <img src={"/layout/img/portfolio/shark.png"} align="left" />
                                        <br /><br />Li'l Bit's adventures were extraordinary. He embarked on thrilling quests to explain the intricacies of $CONE and the blockchain. Whether it was demystifying mining or explaining the benefits of decentralized finance, Li'l Bit made learning about cryptocurrencies a delightful experience.  
                                            <br /><br />The impact of Li'l Bit's efforts extended beyond Reddit's borders. News about him promoting $CONE spread like wildfire across the digital landscape.  
                                            <br /><br />And so, the legend of Li'l Bit, lives on, reminding us that even the most unconventional characters can change the world, one digital transaction at a time. 

                                            <br /><br />His legend has now extended to OpenSea, with his very own PFP collection.  Within this collection, you will find that same lovable character everyone has come to love, but with some new and fun updates and many new attributes.  

                                         
                                        <br /><br /><b>This 4 in 1 generated collection with the follow limits:</b>
                                    </p>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>806 Buckethead Li'l Bits</li>
                                            <li><i className="ri-check-double-line"></i>608 Conehead Li'l Bits</li>
                                            <li><i className="ri-check-double-line"></i>230 HODL Li'l Bits</li>
                                            <li><i className="ri-check-double-line"></i>8,356 Random Li'l Bits</li>
                                            <li><i className="ri-check-double-line"></i>Total: 10,000</li>
                                            <li><i className="ri-check-double-line"></i>Polygon Blochchain </li>
                                            <li><i className="ri-check-double-line"></i>Limit 5 mints per transaction, so we don't bog down the contract, but no limit on how many you can mint</li>
                                        </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </section>  
 
                    <section className="pricing"><a name="pricing" /> 
                        <div className="container"> 
                            <div className="section-title" data-aos="zoom-out">
                                <p>Mint</p>
                            </div> 
                            <div className="row">  
                                <Mint ref={me.pony} load_handler={me.load_handler} alert_handler={me.alert_handler} switch={me.switch}
                                    connect={me.connect} account={me.state.account} network={me.state.network} app={me.props.url}
                                    who=""
                                /> 
                            </div> 
                        </div>
                    </section>  
 
                    <section className="pricing"><a name="links" /> 
                        <div className="container">
                            <div className="section-title" data-aos="zoom-out">
                                <p>Links</p>
                            </div>
                            <div className="row content story" data-aos="fade-up">
                                <div className="col-lg-12 pt-4 pt-lg-0">
                                    <p><b>Bitcone</b></p>
                                    <dd>
                                        <a target="new" href="https://bitcone.lol/">BITCONE ($CONE)</a><br />
                                    <a target="new" href="https://quickswap.exchange/#/swap/v2?currency0=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&currency1=0xbA777aE3a3C91fCD83EF85bfe65410592Bdd0f7c">Quickswap - Trade Bitcone</a><br />
                                    <a target="new" href="https://quickswap.exchange/#/pools/v2?currency0=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&currency1=0xbA777aE3a3C91fCD83EF85bfe65410592Bdd0f7c">QUICKSWAP - ADD LIQUIDITY</a><br />
                                    <a target="new" href="https://www.livecoinwatch.com/price/BitCone-_CONE">Current Price</a><br /> 

                                    </dd>
                                    <p><b>Reddit</b></p>
                                    <dd>
                                    <a target="new" href="https://www.reddit.com/r/the23">r/the23</a><br/>
                                    <a target="new" href="https://www.reddit.com/r/Bucketheads">r/Bucketheads</a><br />
                                    <a target="new" href="https://www.reddit.com/r/ConeHeads">r/ConeHeads</a><br />
                                    </dd>
                                </div>
                            </div>
                        </div>
                    </section> 
 
                    {me.pony.current !=null ? me.pony.current.getlayers() : <span></span>}
 
                    <section className="about"><a name="help"/> 
                        <div className="container">
                            <div className="row content story" data-aos="fade-up">
                                <div className="col-lg-12 pt-4 pt-lg-0">
                                    <p><b>Know Issues</b></p>
                                    <img src={"/layout/img/portfolio/plunger.png"} align="left" />
                                    <ul>
                                        <li>If a project contract does not load propely, just click the "Refresh Contract Button" for that contract. You may have to
                                        click it a few times or refresh the page</li>
                                        <li>When minting, if you get an "out of gas error", that is the blockchain just being too busy, try to mint again.</li>
                                        <li>After minting, if you don't see your NFT image on Opensea, go into the NFT and click the Refresh metadata button, 
                                    then refresh the NFT page.
                                       Depending on how busy the site is, it may take a while for the NFT to display. You may have to do this a few times.</li>
                                    </ul>
                     
                                    <p>If you ever have any questions or concerns, DM us on Twitter: <a href="https://twitter.com/VianiJoe" target="new">@VianiJoe</a></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>   

                    <AlertBox key={'alertbox'} active={me.state.AlertMessage != ''}
                        message={me.state.AlertMessage}
                    callback={() => { me.setState({ AlertMessage: '' }) }} />  


                    <Loader key={'loadbox'} active={me.state.LoadMessage  != ''}
                        message={me.state.LoadMessage} />  
             
            </React.Fragment>
        );
    }
} 

 
