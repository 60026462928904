import React from 'react';  
import { ethers } from "ethers";
import AlertBox from './AlertBox';  
import Loader from './Loader';   
import MintB from './MintB.js';


export default class Lilbud  extends React.Component { 
    constructor(props) {
        super(props);
  
        this.state = {
            AlertMessage: '',
            account: '',
            LoadMessage: '',
            network: '' 
        } 
         
        this.NETWORK_POLY = process.env.REACT_APP_NETWORK_POLY;   
        this.connect = this.connect.bind(this);
        this.checkAccount = this.checkAccount.bind(this);
        this.switch = this.switch.bind(this);
        this.loadcontract = this.loadcontract.bind(this);
        this.alert_handler = this.alert_handler.bind(this);
        this.load_handler = this.load_handler.bind(this); 
  
        this.pony = React.createRef(); 
    } 
     
    componentDidMount() {
        this.checkAccount(); 
    }

    alert_handler(message){
        let me = this;
        me.setState({ AlertMessage: message });
    }

    load_handler(message) {
        let me = this;
        me.setState({ LoadMessage: message });
    }
     

    async loadcontract() {
        let me = this; 
        await me.pony.current.setcontract(me.state.account);  
    }
  
    async checkAccount() {
        let me = this;
        if (window.ethereum) {

            window.ethereum.on('accountsChanged', function (accounts) {  
                window.location.reload(); 
            });
 
            window.ethereum.on('networkChanged', function (networkId) { 
                window.location.reload(); 
            }); 

            let e = "";
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
                .catch((err) => { e = err.message; } );

            if (e != "") {
                me.setState({ AlertMessage: e })
            } else {
                me.setState({ account: accounts[0], network: window.ethereum.chainId });
            } 

            await me.loadcontract();  
 
        } else {
            me.setState({ AlertMessage: 'No wallet installed. Please install a browser wallet. Metamask is recommended' })
        }
    }

    async switch() {
        let me = this;
        const { ethereum } = window;

        try {
            const provider = new ethers.providers.Web3Provider(ethereum);
    
            provider.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: me.NETWORK_POLY }],
            }).then(
                (result) => {
                    return me.NETWORK_POLY;
                })
                .catch(
                    (error) => {
                        if (error.code === 4902) {
                            provider.provider.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: me.NETWORK_POLY,
                                        chainName: 'Polygon',
                                        rpcUrls: ['https://polygon-rpc.com '],
                                        blockExplorerUrls: ['https://polygonscan.com/'],
                                        nativeCurrency: {
                                            symbol: 'MATIC',
                                            decimals: 18
                                        }
                                    }
                                ]
                            }).then(() => {
                                return me.NETWORK_POLY;
                            });
                        }
                    });
             
        }
        catch (err) {
            return '';
            me.setState({ AlertMessage: err.message });
        }
    }

    async connect() {
        let me = this;
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                me.checkAccount()
            } catch (err) {
                me.setState({ AlertMessage: err.message })
            }
        } else {
            me.setState({ AlertMessage: 'No wallet installed. Please install a browser wallet. Metamask is recommended' })
        }
    }

    render() {  
        let me = this;   

        return (
            <React.Fragment>
           
                <main className="main"> 

                    <section className="toon u"><a name="about" /> 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                    <img src="/layout/img/portfolio/bannerB.png" style={{ width: '100%' }} />
                                    <p>
                                        Meet Li'l Bud, the coolest marijuana leaf in the digital world.
                                        With a perpetual grin and a neon-green hue, Li'l Bud embodies the spirit of the modern era, blending cannabis culture with the exciting world of cryptocurrency.
                                    </p><p>
                                        Li'l Bud's journey began when he discovered the potential of CannaCoin to revolutionize the financial landscape.
                                    </p><p>
                                        This charismatic character found his home on the <a href="https://www.reddit.com/r/StellarCannaCoin/" target="new">r/StellarCannaCoin</a>, where he shared insightful posts and educational content about CannaCoin.
                                        Li'l Bud soon became a symbol of knowledge and encouragement for those curious about blockchain technology and the decentralized finance space.
                                    </p><p>
                                        With a digital wallet tucked beneath one of his leaves, Li'l Bud was always ready to facilitate cryptocurrency transactions.
                                        It encouraged people to buy, invest, and use CannaCoin for various purposes,.
                                    </p>
                                    <p>
                                        In its quest to promote CannaCoin, Li'l Bud joined forces with like-minded individuals,
                                        forming a global community that celebrated the potential of blockchain technology.
                                        Together, they explored the endless possibilities of decentralized finance, from yield farming to non-fungible tokens.
                                    </p>
                                    <p>
                                        In the end, Li'l Bud's story is one of transformation and adaptation, showing that even a cartoon character can embrace
                                        the digital age and play a crucial role in demystifying the world of CannaCoin for everyone, while still celebrating its love for the green leaf that started it all.
                                    </p>

                                    <p><b>This is a limited collection:</b></p>
                                    <ul>
                                        <li><i className="ri-check-double-line"></i>Total: 420</li>
                                        <li><i className="ri-check-double-line"></i>Polygon Blochchain </li>
                                        <li><i className="ri-check-double-line"></i>Limit 5 mints per transaction, so we don't bog down the contract, but no limit on how many you can mint</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pricing"><a name="pricing"/>
                        <div className="container">
                            <div className="section-title" data-aos="zoom-out">
                                <p>Mint</p>
                            </div>
                            <div className="row">
                                <MintB ref={me.pony} load_handler={me.load_handler} alert_handler={me.alert_handler} switch={me.switch}
                                    connect={me.connect} account={me.state.account} network={me.state.network} app={me.props.url}
                                    who="B" /> 
                            </div>
                        </div>
                    </section>

                    <section className="pricing"><a name="links" />
                        <div className="container">
                            <div className="section-title" data-aos="zoom-out">
                                <p>Links</p>
                            </div>
                            <div className="row content story" data-aos="fade-up">
                                <div className="col-lg-12 pt-4 pt-lg-0">
                                    <p><b>Bitcone</b></p>
                                    <dd>
                                        <a target="new" href="https://stellarcannacoin.org/">Stellar Cannacoin Website</a><br /> 
                                    </dd>
                                    <p><b>Reddit</b></p>
                                    <dd>
                                        <a target="new" href="https://www.reddit.com/r/StellarCannaCoin/">r/StellarCannaCoin</a><br />
                                        <a target="new" href="https://www.reddit.com/r/cannabiscrypto/">r/Cannabiscrypto</a><br />
                                        <a target="new" href="https://www.reddit.com/r/StellarCannabis/">r/StellarCannabis</a><br />
                                        <a target="new" href="https://www.reddit.com/r/StellarNFTs/">r/StellarNFTs</a><br />
                                    </dd>
                                </div>
                            </div>
                        </div>
                    </section>
 
                    <section className="about"><a name="help" />
                        <div className="container">
                            <div className="row content story" data-aos="fade-up">
                                <div className="col-lg-12 pt-4 pt-lg-0">
                                    <p><b>Know Issues</b></p>
                                    <img src={"/layout/img/portfolio/plungerB.png"} align="left" />
                                    <ul>
                                        <li>If a project contract does not load propely, just click the "Refresh Contract Button" for that contract. You may have to
                                            click it a few times or refresh the page</li>
                                        <li>When minting, if you get an "out of gas error", that is the blockchain just being too busy, try to mint again.</li>
                                        <li>After minting, if you don't see your NFT image on Opensea, go into the NFT and click the Refresh metadata button,
                                            then refresh the NFT page.
                                            Depending on how busy the site is, it may take a while for the NFT to display. You may have to do this a few times.</li>
                                    </ul>

                                    <p>If you ever have any questions or concerns, DM us on Twitter: <a href="https://twitter.com/VianiJoe" target="new">@VianiJoe</a></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>   

              
                    <AlertBox key={'alertbox'} active={me.state.AlertMessage != ''}
                        message={me.state.AlertMessage}
                    callback={() => { me.setState({ AlertMessage: '' }) }} />  


                    <Loader key={'loadbox'} active={me.state.LoadMessage  != ''}
                        message={me.state.LoadMessage} />  
             
            </React.Fragment>
        );
    }
} 

 
