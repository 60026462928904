import React from "react"; 
import PropTypes from "prop-types";
 
const AlertBox = ({ message, active, callback, timeout,  ...props }) => {  
        var yPosition = (window.scrollY || document.body.scrollTop || document.documentElement.scrollTop || 0) + 200;
        var style = active ? { top: yPosition + 'px' }  : {display : "none", top: yPosition + 'px' }

        return (
            <React.Fragment  key={'ab0'} >
                <div className="app_popupbox_background" style={style}  key={'ab1'}></div>
                <div className="app_popupbox_inner_background" style={style}  key={'ab2'}>
                    <center className="app_popupbox_text">
                        <h4>{message}</h4>
                        <div className="btn-wrap">
                            <button onClick={callback} className="btn-buy">Ok</button> 
                        </div>
                    </center>
                </div>
                {	
                      timeout !=null ? setTimeout( callback,  timeout)  : null
                }
            </React.Fragment>
        );    
}; 

 
AlertBox.propTypes = {
    message : PropTypes.string.isRequired,
    active : PropTypes.bool,
    callback: PropTypes.func.isRequired,
    timeout : PropTypes.number 
};
  
  AlertBox.defaultProps = {
    message: ""  ,
    active : false,
    timeout : null
};
  
  
export default AlertBox ;