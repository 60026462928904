import React, { Component } from 'react'; 
 
export default class Digital extends Component {  
  render () {
      return ( 
          <React.Fragment> 
 
              <div className='main' > 

                  <section className="toon d"><a name="car" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/caramelmoon-digitial">Caramel Moon Digitial <img src="/layout/img/opensea.webp" className="opensea" /></a></h1>
                                  <p><img src="/layout/img/portfolio/car.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />
                                      Caramel Moon is an amateur model, master powerlifter, and above all, grandma!
                                      She is here to inspire woman of all ages to enjoy life, feel good about themselves and always know,
                                      that you are never too old to flaunt your beauty.
                                      This collection will be illustrated NFTs based off photoshoots we have done.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </section>
                   
                  <section className="toon u"><a name="insane" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/insane-toon-posse">Insane Toon Posse <img src="/layout/img/opensea.webp" className="opensea" /></a>
                                      </h1> 
                                  <p>  <img src="/layout/img/portfolio/toon.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />              
                                      Sparky, "The Angry Unicorn", teamed up with Porky "The Pissed Off Pig", Buster "The Mad Mutt", Luna "The Crazed Cat" and Bounce "The Badass Bunny" to form The Insane Toon Posse. They have taken it upon themselves to fight bullying in their toon world. They believe that everyone deserves to be treated with kindness and respect, and they won't stand idly by while bullies try to make others feel small and take advantage of anyone they can. For every 1 ETH in promary sales, 0.1 ETH will be donated to Stomp Out Bullying and 0.05 ETH will be given to a random Insane Toon holder, each NFT held is an entry for the random drawing
                                  </p>
                              </div>
                          </div>
                      </div> 
                </section> 

                  <section className="toon p"><a name="toon" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/insane-toon-posse-custom">Insane Toon Posse Custom <img src="/layout/img/opensea.webp" className="opensea" /></a></h1> 
                                  <p> <img src="/layout/img/portfolio/toon1.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />
                                      Insane Toon Posse Custom is a break off collection of all cutsom NFTs based off the original Insane Toon Posse collection.DM on Twitter for your custom posse member!
                                  </p>
                              </div>
                          </div>
                      </div>
                  </section> 
                   
                  <section className="toon b"><a name="lbit" /> 
                     <div className="container">
                         <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1><a target="new" href="https://opensea.io/collection/lil-bit-and-friends">Li'l Bit And Friends (editions) <img src="/layout/img/opensea.webp" className="opensea" /></a></h1> 
                                  <p><img src="/layout/img/portfolio/bit.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />             
                                        Li'l Bit the baby $CONE and inspired conehead friends
                                  </p>
                              </div>
                          </div>
                      </div>                   
                </section>  

                  <section className="toon c"><a name="lbit1" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/li-l-bit">Li'l Bit Generated <img src="/layout/img/opensea.webp" className="opensea" /></a></h1>
                                  <p><img src="/layout/img/portfolio/bit2.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />
                                      In numerous subs on Reddit, a curious cartoon character named Li'l Bit emerged from the digital realm. Li'l Bit was a magical creation born from a fusion of augmented reality and a touch of crypto magic. He was created to promote the wonders of Bitcone ($CONE) and blockchain technology to the members of Reddit.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </section>  
                   
                  <section  className="toon d"><a name="bud" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/li-l-bud">Li'l Bud <img src="/layout/img/opensea.webp" className="opensea" /></a></h1>
                                  <p><img src="/layout/img/portfolio/bud.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />
                                      Li'l Bud is a small limited collection of 420 NFTs, the were randomly generated from all hand drawn attributes.Everyone needs a best Bud!Li'l Bud was introduced to the fun sub StellarCannaCoin by his best buddy Li'l Bit of the Coneheads. They spend many nights relaxing with a good smoke.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </section>
                </div> 
          </React.Fragment>
    );
  }
}
