import React, { Component } from 'react'; 
 
export default class Photo extends Component {  
  render () {
      return ( 
          <React.Fragment> 
              <div className='main' >  
                  <section className="toon u"><a name="joe" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/viani-photo-v2">Joe Viani Photo <img src="/layout/img/opensea.webp" className="opensea" /></a>
                                      </h1> 
                                  <p>  <img src="/layout/img/portfolio/joe.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />              
                                     This is a collection of various photos, I have taken, of various subjects, along with some fun Photoshop composites.
                                  </p>
                              </div>
                          </div>
                      </div> 
                </section> 


                  <section className="toon p"><a name="carmel" /> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1><a target="new" href="https://opensea.io/collection/caramelmoon">Caramel Moon <img src="/layout/img/opensea.webp" className="opensea" /></a></h1> 
                                  <p> <img src="/layout/img/portfolio/carm.png" height="400px" style={{ display: "block", marginBottom: "12px" }} />
                                    Caramel Moon is an amateur model, master powerlifter, and above all, grandma!
                                  She is here to inspire woman of all ages to enjoy life, feel good about themselves and always know, that you are never too old to flaunt your beauty.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </section> 
     
                  <section  className="toon b"><a name="hum" /> 
                     <div className="container">
                         <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1><a target="new" href="https://opensea.io/collection/vianihummingbirds">Hummingbirds <img src="/layout/img/opensea.webp" className="opensea" /></a></h1>
                                  <p>       <img src="/layout/img/portfolio/hum.png" height="400px" style={{ display: "block", marginBottom : "12px" }} />             
                          These magical creatures that flit about with lightning-fast speed, leaving trails of sparkling dust in their wake have iridescent plumage
                      shimmers like jewels in the sunlight, and their long, thin beaks are capable of sipping nectar from even the most
                      exotic and elusive flowers.
                                  </p>
                              </div>
                          </div>
                      </div>                   
                </section>  
   
                </div> 
          </React.Fragment>
    );
  }
}
