import React, { Component } from 'react'; 
 
export default class Insane extends Component {  
  render () {
      return ( 
          <React.Fragment>
              <div className='main' > 
                  <img src="/layout/img/portfolio/header.png" className="img-fluid animated" alt="" />
 

                <section id="hero" className="d-flex align-items-center"> 
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1>Our Story</h1>
                                  <p>    Sparky, “The Angry Unicorn”, teamed up with Porky “The Pissed Off Pig”, Buster “The Mad Mutt”, Luna “The Crazed Cat” and Bounce “The Badass Bunny"
                                  to form The Insane Toon Posse. They have taken it upon themselves to fight bullying in their toon world.
                                      They believe that everyone deserves to be treated with kindness and respect, and they won't stand idly by while bullies try to
                                      make others feel small and take advantage of anyone they can.
                                        Though they came from different situations, they came together and work tirelessly to create a world where bullying is not tolerated,
                                      and where everyone can feel safe and respected. They try their best to be diplomatic about their message and approach with bullies but have
                                      been known to go a bit crazy from time to time to get their point across and stop the bulling, thus earning the nickname the Insane Toon Posse.
                                    </p> 
                              </div> 
                          </div>
                      </div> 
                </section>

 

                <section id="sparky" className="toon u">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200">
                                  <h1>Sparky</h1><h2>The Angry Unicorn</h2>
                                  <p>            <img src="/layout/img/portfolio/unicorn.png" height="400px" align="left" />
                                      Sparky was a beautiful unicorn with a kind heart. 
                                      Sparky loved to spend his days frolicking in the meadow and spreading joy wherever he went. 
                                      However, his friendly nature often made him a target for those who wanted to take advantage of him. 
                                      For years, Sparky had been lending his magical powers to help other animals in the meadow. 
                                      He would use his healing abilities to help others that were hurt, his ability to see the near future to help others avoid danger. 
                                      Once his abilities became well known, many animals began to take advantage of his kindness and started to force him to use his powers for their own personal gain.                                        It was when Sparky needed help and not a single animal showed up to help, he had enough. 
                                        He began to feel resentful and angry, and his once peaceful nature was replaced by a deep-seated rage.
                                        To channel his rage, he decided to get with other animals that have been through similar experiences and fight back 
                                        against those who would force others to do things against their will.
                                    </p>
                              </div>
                          </div>
                      </div> 
                        </section> 

    
                  <section id="porky" className="toon p">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1>Porky</h1><h2>The Pissed Off Pig</h2>
                                  <p>          <img src="/layout/img/portfolio/pig.png" height="400px" align="right" />          
                                      One day, as Porky was being loaded onto a truck bound for the slaughterhouse, he realized that his life on the farm was nothing but a sham. 
                                      All the wonderful feedings and special attention, he received was all a false way to make him feel important, while hiding the real harsh fact,
                                      that he was being fattened for the slaughter.
                                      Feeling betrayed and abandoned, along with the feelings of self-doubt about his self-worth, filled him with enough rage to help him break free 
                                      from his cage and escape.
                                        Once, free, he vowed to help others that were in similar situations and joined Sparky to form the Insane Toon Posse.
                                </p>
                              </div>
                          </div>
                      </div> 
                        </section> 

    
                              <section id="bounce" className="toon b">
                                  <div className="container">
                                      <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1>Bounce</h1><h2>The Badass Bunny</h2>
                                  <p>       <img src="/layout/img/portfolio/bunny.png" height="400px" align="left" />             
                                      
                                      Bounce, the hyperactive rabbit, was tired of being pushed around by the other animals in the cartoon world. 
                                      He was small and often underestimated, so it made him an easy target for ridicule and name calling.
                                      With a fierce spirit and a determination to stand up for himself, Bounce decided that he had had enough. 
                                      He was going to fight back against the animals who had been bullying him. 
                                      He began to train tirelessly, honing his speed and agility, and developing new moves to catch his opponents off guard.
                                    After many encounters, he was becoming a legend around the forest and finally was asked to join Insane Toon Posse,   
                                      to help them in their endeavors to rid evil from this world.
                       </p>                                     </div>
                          </div>
                      </div>                   
                        </section> 

  
                                          <section id="shadow" className="toon c">
                                              <div className="container">
                                                  <div className="row">
                                                      <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1>Shadow</h1><h2>The Crazed Cat</h2>
                                  <p>         <img src="/layout/img/portfolio/cat.png" height="400px" align="right" />              
                                      Shadow had always been an easy target for bullies. He was constantly picked on for his small size, eccentric behavior, 
                                      and erratic movements. But one day, Shadow had had enough. He was sick of being pushed around and decided to take a stand.
                                         Despite his small size, Shadow fought back fiercely. He used his agility and speed to dodge his opponent's attacks and
                                       landed precise blows of his own. His opponents were taken aback by his sudden burst of strength, 
                                      and they struggled to keep up with his relentless energy.  There was no opponent to large to stand up to his fury.
                                                          Eventually he joined the Insane Toon Posse to put his skills to work helping others.
                            </p>                               </div>
                                      </div>
                                  </div> 
                        </section> 

                                                      <section id="buster" className="toon d">
                                                          <div className="container">
                                                              <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 story" data-aos="fade-up" data-aos-delay="200"> 
                                  <h1>Buster</h1><h2>The Mad Mutt</h2>
                                  <p>   <img src="/layout/img/portfolio/dog.png" height="400px" align="left" />
                                  Buster was the runt of the litter, so naturally he was always being picked on by other dogs. 
                                      Growing up in such an environment never got him down, it only made him stronger. 
                                      He learned at an early age that the only way to overcome his bullies was to stand up and fight back.
                                  He was determined to make his bite as bad as his bark, so he developed his skills then headed out to help others do the same. 
                                      Eventually he crossed paths with Insane Toon Posse and was recruited to join.
                                                                      </p>                                </div>
                          </div>
                      </div> 
             
                  </section>

 
                  <section id="disclaimer" className="toon disclaimer">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 " data-aos="fade-up" data-aos-delay="200">
                                  <h1>IMPORTANT INFO</h1>
                                  <p> 
                                          Though some of the artwork in this collection may seem confrontational and violent in nature, we at the Insane Toon Posse
                                          want to make it clear that we DO NOT CONDONE violence in any form,
                                          whether it is physical, verbal, or emotional. Violence can cause harm, trauma, and lasting damage
                                          to individuals and communities, and it is important to do everything we can to prevent it.
                                  </p>
                                  <p> 
                                      Instead, we encourage individuals to seek out peaceful and non-violent means of resolving conflicts
                                      and standing up for themselves. This can include using communication, negotiation, mediation,
                                      and other methods that respect the dignity and well-being of all parties involved.
                                       </p>
                                  <p> 
                                      In summary, we want to reiterate that we STRONGLY CONDEMN VIOLENCE and support peaceful means of conflict resolution.
                                      </p> 
                              </div>
                          </div>
                      </div>
                  </section> 
                </div> 
          </React.Fragment>
    );
  }
}
