import React, { Component } from 'react';
import './custom.css'
import Lilbit from './Lilbit.js';
import Lilbud from './Lilbud.js';
import Joe from './Joe.js';
import Insane from './Insane.js'; 
import Photo from './Photo.js';
import Digital from './Digital.js';

export default class Nav extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
            page: 'joe'
        }  
         
        this.changepage = this.changepage.bind(this); 
    }

    changepage(what) { 
        this.setState({ page: what });
    } 
  
    render() {    

        let what = <Joe />;  
        let open = <a href="https://opensea.io/joevianiofficial" target="new" key={'j4'} ><img src="/layout/img/opensea.webp" className="opensea" /></a>;

        switch (this.state.page) {
            case 'bit':
                what = <Lilbit />; 
                open = <li key={'ll4'}><a href="https://opensea.io/collection/li-l-bit" target="new" key={'jl4'} ><img src="/layout/img/opensea.webp" className="opensea" /></a></li>; 
                break;
            case 'bud':
                what = <Lilbud />; 
                open = <a href="https://opensea.io/collection/li-l-bud" target="new" key={'b4'} ><img src="/layout/img/opensea.webp" className="opensea" /></a>;
                break;  
            case 'photo':
                what = <Photo />; 
                break;
            case 'digital':
                what = <Digital />; 
                break;
            default: 
                what = <Joe />;  
                break;
        }

      return (<React.Fragment>
          <header id="header" className="fixed-top d-flex align-items-center  header-transparent ">
              <div className="container d-flex align-items-center justify-content-between">
                  <div className="section-title section-titletitle" >
                      <p>Joe Viani</p>
                  </div>
                  <nav id="navbar" className="navbar">
                      <ul>
                          <li><a className="nav-link scrollto" onClick={() => { this.changepage('joe') }} >Home</a></li>
                          <li><a className="nav-link scrollto" onClick={() => { this.changepage('bit') }} >Li'l Bit Mint</a></li>
                          <li><a className="nav-link scrollto" onClick={() => { this.changepage('bud') }} >Li'l Bud Mint</a></li>  
                          <li><a className="nav-link scrollto" onClick={() => { this.changepage('photo') }} >Photography Collections</a></li> 
                          <li><a className="nav-link scrollto" onClick={() => { this.changepage('digital') }} >Digital Art Collections</a></li> 
                          <li><a href="https://twitter.com/VianiJoe" className="twitter" target="new"><i className="bx bxl-twitter"></i></a></li>
                          {open}
                      </ul>
                      <i className="bi bi-list mobile-nav-toggle"></i>
                  </nav> 
                  <div className="logo">
                      <a href="#help"><i className="bi bi-question-diamond"></i></a>
                  </div>
              </div> 
          </header>  
          {what}  
      </React.Fragment>);
  }
}
