import React from "react"; 
import PropTypes from "prop-types";
 
const Loader = ({ message, active,  ...props }) => {  
        var yPosition = (window.scrollY || document.body.scrollTop || document.documentElement.scrollTop || 0) + 200;
        var style = active ? { top: yPosition + 'px' }  : {display : "none", top: yPosition + 'px' }

        return (
            <React.Fragment  key={'ab0'} >
                <div className="app_popupbox_background" style={style}  key={'ab1'}></div>
                <div className="app_popupbox_inner_background" style={style}  key={'ab2'}>
                    <center className="app_popupbox_text">
                        <h4>{message}</h4>
                        <center><div className="loader"></div></center>
                    </center>
                </div>  
            </React.Fragment>
        );    
}; 

 
Loader.propTypes = {
    message : PropTypes.string.isRequired,
    active : PropTypes.bool 
};
  
Loader.defaultProps = {
    message: ""  ,
    active : false 
};
  
  
export default Loader ;