import React from 'react';
import { ethers } from "ethers";
import cntPony from './assets/abi.json';  
import cntWinn from './assets/win.json'; 
import Layers from './Layers.js';
import $ from 'jquery';
require('dotenv').config();
 

export default class Mint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            LilBitContract: null,
            WinContract: null,
            ContractVals: {
                nfts: [],
                free: 0
            },
            minted: false, 
            loaderror: '',
            account  : ''
        }

        this.askContractToMintNft = this.askContractToMintNft.bind(this);
        this.refreshContractinfo = this.refreshContractinfo.bind(this);
        this.roundToTwo = this.roundToTwo.bind(this); 
        this.CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_PROD;
        this.NETWORK = process.env.REACT_APP_NETWORK_POLY;
        this.CONTRACT_ADDRESSW = process.env.REACT_APP_CONTRACTW_PROD;

    }

    async setcontract(acc) {
        let me = this;
        try {
            let vals = await me.refreshContractinfo(acc);
            me.setState({ account: acc, loaderror: '', ContractVals: vals.ContractVals, minted: false});
            me.props.load_handler("");
        } catch (err) {
            me.setState({ loaderror: err.message + ' please try and reload the page' });
            me.props.load_handler("");
        }
    }
     
    async refreshContractinfo(acc) {

        let me = this;
        if (me.props.network != this.NETWORK) {
            return { ContractVals: me.state.ContractVals }
        }

        if (me.state.LilBitContract == null) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const ContractPony = new ethers.Contract(this.CONTRACT_ADDRESS, cntPony.abi, signer);
            ContractPony.on("Minted", async (address, success) => {
                me.setState({
                    minted: true 
                });
                me.props.load_handler("");
                me.props.alert_handler("Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea."); 
                await me.setcontract(acc);
            });

            const ContractWinner = new ethers.Contract(this.CONTRACT_ADDRESSW, cntWinn.abi, signer);
     
            me.setState({ LilBitContract: ContractPony, WinContract: ContractWinner });
        }

        try { 
            me.props.load_handler("Getting contract data...");
            let _nfts = await me.state.LilBitContract.GetNFTs(); 
            let count = await me.state.WinContract.GetAmount(acc);
            me.props.load_handler("");
            var ff = [];
            for (let i = 0; i < _nfts.length; i++) {
                if (_nfts[i].id.toNumber() != 5) {
                    ff.push(
                        {
                            name: _nfts[i].name,
                            id: _nfts[i].id.toNumber(),
                            count: _nfts[i].count.toNumber(),
                            max: _nfts[i].max.toNumber(),
                            price: ethers.utils.formatEther(_nfts[i].price),
                            eth: _nfts[i].price
                        }
                    );
                }
            }
            return {
                ContractVals: {
                    nfts: ff,
                    free: count
                }
            };
        }
        catch (err) {
            me.props.load_handler("");
        }
    }

    async askContractToMintNft(nft) {
        let me = this; 

        try {
            const { ethereum } = window;
            $('#' + nft.id + 'mint_btnp').hide();
            let number = $('#' + nft.id + 'pobox').val();
            if (ethereum) {
                me.props.load_handler("Minting your NFT...please wait.");
                let len = nft.price.toString().length + 2
                let total = (nft.price * number);
                total = parseFloat(total).toFixed(len);

                try {
                    let nftTxn = await me.state.LilBitContract.Mint(nft.id, number, { value: ethers.utils.parseEther(total).toString() });
                    await nftTxn.wait(); 
                    $('#' + nft.id + 'mint_btnp').show();
                } catch (error) {
                    if (error.data) {
                        if (error.data.message.includes('insufficient funds')) {
                            me.props.alert_handler('Insufficient funds. You need at least ' + me.roundToTwo(total) + ' MATIC + gas fees.');
                        } else {
                            me.props.alert_handler(error.data.message);
                        }
                    } else {
                        me.props.alert_handler(error.message);
                    }
                    me.props.load_handler("");
                    $('#' + nft.id + 'mint_btnp').show();
                }
            } else {
                me.props.load_handler("");
                me.props.alert_handler("Connection issue.");
                $('#' + nft.id + 'mint_btnp').show();
            }
        } catch (error) {
            me.props.load_handler("");
            $('#' + nft.id + 'mint_btnp').show();
            if (error.data) {
                me.props.alert_handler(error.data.message);
            } else {
                me.props.alert_handler(error.message);
            }
        }
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    getcount() {
        return this.state.ContractVals.free; 
    }

    getlayers() {
        let me = this;
        return me.state.ContractVals.free > 0 ?
        <section id="builder" className="about">
            <div className="container">
                <div className="section-title" data-aos="zoom-out">
                    <p>Build A Bit</p>
                </div>
                <div className="row content" data-aos="fade-up">
                    <div className="col-lg-12 pt-4 pt-lg-0">
                            <Layers load_handler={me.props.load_handler} alert_handler={me.props.alert_handler} account={me.state.account}  />
                    </div>
                </div>
            </div>
        </section> : <span></span>;
    }

    renderMintUI() {
        let me = this;
        let gut = [];
 
        if (me.props.account === '') {
            return <div className="col-lg-3 col-md-6" style={{ marginBottom: '23px' }} key={'x'} >
                <div className="box featured" data-aos="zoom-in" data-aos-delay="500">
                    <img src={"/layout/img/portfolio/1" + me.props.who + ".png"} />
                    <ul>
                        <li>Error loading contract!</li>
                    </ul>
                    <div className="btn-wrap">
                        <button onClick={me.props.connect} id="mint_btnp" className="btn-buy">Connect wallet to Mint</button>
                    </div>
                </div></div>;  
        }

        if (me.props.account != '' && me.props.network != this.NETWORK) {
            return <div className="col-lg-3 col-md-6" style={{ marginBottom: '23px' }} key={'x'} >
                <div className="box featured" data-aos="zoom-in" data-aos-delay="500">
                    <img src={"/layout/img/portfolio/1" + me.props.who + ".png"} />
                    <ul>
                        <li>Error loading contract!</li>
                    </ul>
                    <div className="btn-wrap">
                        <button onClick={me.props.switch} id="mint_btnp" className="btn-buy">Switch to Polygon</button>
                    </div>
                </div></div>; 
        }

        if (me.state.loaderror != '') {
            return <div className="col-lg-3 col-md-6" style={{ marginBottom: '23px' }} key={'x'} >
                <div className="box featured" data-aos="zoom-in" data-aos-delay="500">
                    <img src={"/layout/img/portfolio/1" + me.props.who + ".png"} />
                <ul>
                    <li>Error loading contract!</li>
                </ul>
                    <div className="btn-wrap">
                        <button onClick={() => { me.setcontract(me.state.account) }} id="mint_btnp" className="btn-buy">Refresh Contract</button>
                </div>
                </div></div>;
        }

        me.state.ContractVals.nfts.map((nft, i) => {
            gut.push(<div className="col-lg-3 col-md-6" style={{ marginBottom: '23px' }} key={nft.id} >
                <div className="box featured" data-aos="zoom-in" data-aos-delay="500">
                    <h3 style={{ background: '#d93a96' }} >{
                        nft.id == 1 ? "Li'l Bit" :
                            nft.id == 2  ? "HODL Hats" :
                                nft.id == 3 ? "Bucketheads" :
                                    nft.id == 4 ? "Coneheads" : ""
                    }</h3>
                    <img src={"/layout/img/portfolio/" + nft.id + me.props.who + ".png"} />
                    <h4>{nft.price} <span> MATIC (+gas)</span></h4>
                    <ul>
                        <li className="left">There are only {(nft.max - nft.count)} left!</li>
                        <li>
                            <select id={nft.id + 'pobox'} className="pricing_select" >
                                <option value={'1'} key={'1'}>1</option> 
                                <option value={'2'} key={'2'}>2</option> 
                                <option value={'3'} key={'3'}>3</option> 
                                <option value={'4'} key={'4'}>4</option> 
                                <option value={'5'} key={'5'}>5</option> 
                            </select>
                        </li>
                    </ul>
                    <div className="btn-wrap">
                        <button onClick={() => { me.askContractToMintNft(nft) }} id={nft.id + "mint_btnp"} className="btn-buy">Mint</button>
                    </div></div></div>);
        });

        return gut;
    }

    render() { 
        return (
            <React.Fragment> 
                {this.renderMintUI()} 
            </React.Fragment>
        );
    }
} 